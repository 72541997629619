.claims-activity-e-alerts-button-div {    
    display: flex;
    flex-direction: column;

    .claims-activity-e-alerts-button {        
        height: 2.5rem;
        min-width: 7rem;
        padding: 0.2rem;
        font-size: 1rem;        
    }
}

.claim-activity-email-maintenance-dialog-container {
    height: auto;
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (max-width: 849px) {
        padding-left: 0;
        padding-right: 0;              
    }       

    .sai-inner-div-policy-search {
        display: flex;
        flex-direction: row;        
        justify-content: space-between;    
        padding-bottom: 3%;
        height: auto;
        width: 100%;     
        
        @media (max-width: 849px) {
            width: 100%;       
            display: flex;
            flex-direction: column;  
            align-content: space-between;        
        }   

        .sai-selection-div-outer {        
            display: flex;
            width: 40%;      

            @media (max-width: 849px) {
                width: 100%;   
                padding-left: 1rem;
                padding-right: 1rem;                          
            }   
            
            .sai-selection-div-inner {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;                    
                width: 100%;  
                align-items: flex-start;
                
                .sai-form-policy-input {
                    width: 70%;                
                }
        
                .sai-number-add-button-container {
                    width: 25%;
                    margin-top: 1.8rem;                
                }
            }
        }  

        .sai-number-display-div {        
            display: flex;
            flex-direction: column;
            width: 30%;    
            align-content: flex-start;     
            
            @media (max-width: 849px) {
                width: 100%;  
                padding-left: 1rem;
                padding-right: 1rem;                                         
            }  
        }  
        
        .sai-number-display-buttons-div {       
            width: 25%;

            @media (max-width: 849px) {
                width: 100%;                           
            }  

            .sai-number-button-container {           
                width: 100%;
                display: flex;           
                flex-direction: column;     

                @media (max-width: 849px) {
                    flex-direction: row;
                    justify-content: space-between;    
                    padding-left: 1rem;
                    padding-right: 1rem;                       
                } 
                
                .sai-number-btns {
                    height: 2rem;
                    width: 90%;

                    @media (max-width: 849px) {
                        width: 45%;                           
                    } 
                }
            }
        }   
    }

    .sai-number-save-alert-div {    
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 1rem;   
        padding-right: 1rem;

        @media (max-width: 500px) {
            justify-content: stretch;    
            padding-left: 1rem;                                 
        }  
        
        .sai-number-save-alert-btn {
            height: 2rem;
            width: 11rem;   
            
            @media (max-width: 500px) {
                width: 100%;                          
            }  
        }
    }

    .sai-delete-sel-btn-div {    
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;
        margin-bottom: 1rem;   
        padding-left: 0.8rem;

        @media (max-width: 500px) {               
            padding-left: 1rem;   
            padding-right: 1rem;                  
        }

        .sai-delete-sel-btn {
            height: 2rem;
            width: 11rem;
            
            @media (max-width: 500px) {
                width: 100%;                                             
            }
        }    
    }

    .sai-close-btn-div {    
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        margin-bottom: 1rem;       
        padding-right: 1rem;

        @media (max-width: 500px) {             
            padding-left: 1rem;                  
        }

        .sai-close-btn {        
            height: 2rem;
            width: 11rem;

            @media (max-width: 500px) {
                width: 100%;                               
            }
        }    
    }
}