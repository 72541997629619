.welcome-banner {    
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;   
    margin-top: 1rem;
    width: 100%;
    height: 100px;    
    margin: 0;
    padding-left: 0.1rem;    
    padding-right: 0.1rem;            

    @media (max-width: 800px) {
        width: 100%;        
    }

    @media (max-width: 749px) {        
        flex-direction: column;     
        height: 150px;  
    }

    @media (max-width: 500px) {
        width: 100%;        
    }
    
    .welcome-banner-h3 {               
        @media (max-width: 800px) {
            width: 100%;  
            font-size: 1.2rem;      
        }

        @media (max-width: 749px) {
            text-align: center;                
        }
    
        @media (max-width: 500px) {                 
            font-size: 1rem;  
        }     
    }

    .welcome-banner-search-div {        
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-content: center;
        width: auto;                   

        @media (max-width: 749px) {
            justify-content: center;    
        }      

        .welcome-banner-search-text-div {
            font-size: 1rem;          

            @media (max-width: 749px) {
                text-align: center;    
            }
        }
        
        .welcome-banner-inner-search-div {            
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            margin-bottom: 0;  
            
            .welcome-banner-search-input {
                border-radius: 0.1875rem 0px 0px 0.1875rem;
            }

            .search-box-override-btn {
                cursor: pointer;
                background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12.438%2013.145a7.5%207.5%200%2011.707-.707l4.709%204.708a.5.5%200%2001-.708.708l-4.708-4.709zM7.5%2014a6.5%206.5%200%20100-13%206.5%206.5%200%20000%2013z%22%20fill%3D%22%23E01719%22%2F%3E%3C%2Fsvg%3E);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 1.125rem;
                border: 0.0625rem solid rgb(224, 23, 25);               
                border-radius: 0px 0.1875rem 0.1875rem 0px;
                display: block;
                width: 2.75rem;
                height: 2rem;
                background-color: white;
            }

            .search-box-override-btn:focus {
                border: 0.13rem solid #0091EB;
            }
        }      
    }
}