
    .agent-account-search-inner-div-account-search {       
        display: flex;
        flex-direction: column;  
        width: 55%;

        @media (max-width: 800px) {
            width: 100%;          
        }
    
        @media (max-width: 500px) {
            width: 100%;                   
        }

        .agent-account-search-form-container {
            display: flex;
            flex-direction: row;
            width: 100%;               
            
            @media (max-width: 800px) {                
                width: 100%;        
            }   
                       
            @media (max-width: 640px) {
                flex-direction: column;
                width: 100%;        
            }          
            
            .agent-account-search-input {
                display: flex;
                flex-direction: column;
                align-content: space-between;               
                width: 72%;    
                
                @media (max-width: 800px) {                    
                    width: 80%;        
                }  
                                               
                @media (max-width: 640px) {
                    width: 100%;                    
                }      
            }
            
            .agent-account-search-button-container {               
                width: 28%; 
                display: flex;
                margin-left: auto;
                margin-right: 0;
                margin-top: auto;
                margin-bottom: 1.55rem;   
                justify-content: flex-end;                    
                
                @media (max-width: 800px) {                    
                    display: flex;
                    justify-content: flex-end;
                    width: 18%;        
                }  
                            
                @media (max-width: 640px) {
                    width: 100%;        
                }   
                
                .agent-act-search-btn {  
                    @media (max-width: 640px) {
                        width: 100%;                                      
                    }   
                }
            }
        }
    }

   