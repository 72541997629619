//////////////////////////////////
// MAIN SASS FILE - IMPORT HERE //
//////////////////////////////////
@import '~@trv-tds/core/dist/sass/foundations';
// or
//@import '~@trv-tds/core/dist/sass/foundations-with-grid';
// to include utility classes...
@import '~@trv-tds/core/dist/sass/utilities';

@import '~@trv-tds/core/dist/css/tds.min.css';

@import './WelcomeBanner.scss';
@import './Dashboard.scss';
@import './HomeForm.scss';
@import './AccountIdentification.scss';
@import './AgentAccountSearch.scss';
@import './EmailMaintenance.scss';
@import './SideNavigation.scss';
@import './ReportingOptions.scss';
@import './ClaimActivity.scss';
@import './common.scss';
@import './SavedParametersSearchResults.scss';

/* TDS override */
body {
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 1.125rem !important;
    line-height: 1.5 !important;
}
// .tds-button--tertiary.tds-button--small {    
//     cursor: pointer !important;
//     background-color: none !important;
// }

// .tds-button--tertiary.tds-button--small:hover {        
//     background: none !important;
//     color: #007395 !important;
//     cursor: pointer !important;
// }

.app-main-class {
    width: 80%;
    margin: 0 auto;

    @media (max-width: 1767px) {      
        width: 70%;      
        margin: 0 auto;  
    }

    @media (max-width: 1495px) {      
        width: 75%;      
        margin: 0 auto;  
    }

    @media (max-width: 1400px) {      
        width: 80%;      
        margin: 0 auto;  
    }

    @media (max-width: 1313px) {      
        width: 85%;      
        margin: 0 auto;  
    }    

    @media (max-width: 1220px) {      
        width: 95%;      
        margin: 0 auto;  
    }


    @media (max-width: 800px) {      
        width: 100%;  
        margin: 0 auto;      
    }
}

a:hover {    
    text-decoration: none !important;    
    background: transparent !important;
    cursor: pointer !important;
}

a:focus {
    background: transparent !important;
    text-decoration: none !important;
    cursor: pointer !important;
}

//a:active, .tds-a:active, a.active, .active.tds-a, .active {
    //background-color: #F0F7FE;    
    //background-color: transparent !important;
    //color: black !important;    
//}

input{
    height: 2rem;
}

.search-box-override-btn-report-options {
    cursor: pointer;
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12.438%2013.145a7.5%207.5%200%2011.707-.707l4.709%204.708a.5.5%200%2001-.708.708l-4.708-4.709zM7.5%2014a6.5%206.5%200%20100-13%206.5%206.5%200%20000%2013z%22%20fill%3D%22%23E01719%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.125rem;
//    border: 0.0625rem solid rgb(224, 23, 25);
    border: 0.0625rem solid rgb(118, 118, 118);
    border-radius: 0px 0.1875rem 0.1875rem 0px;
    display: block;
    width: 20%;
    height: 2rem;
    background-color: white;
}

.wireframe-p-font-regular {
    //font-family: NeueHaasUnica, sans-serif;
    font-size: 0.75rem;    
    color: #46494d;    
}

.wireframe-p-font-italic {
    //font-family: NeueHaasUnica, sans-serif;
    font-size: 0.75rem;
    font-style: italic;
    color: #46494d;    
}

.wireframe-p-font-bold {
    //font-family: NeueHaasUnica, sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
    color: #46494d;    
}

.wireframe-one-click-reports-links {
    //font-family: NeueHaasUnica, sans-serif;
    font-size: 0.85rem;
    font-weight: bold;
    text-align: left;
    color: #6b6d71;    
}

.wireframe-one-click-reports-p-font-regular {
    //font-family: NeueHaasUnica, sans-serif;
    font-size: 0.75rem;    
    // color: #6b6d71;  
}

.wireframe-one-click-reports-p-font-bold {
    //font-family: NeueHaasUnica, sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
    color: #6b6d71;  
}

.contact-us-p-font {    
    font-size: 0.9rem;
    font-weight: normal;     
    text-transform: none;
    letter-spacing: 0.0125rem;
}

.optional-report-criteria-add-font {
    //font-family: NeueHaasUnica, sans-serif;
    font-size: 1rem;    
    color: #007395;
    font-weight: 500;
}

.table-button-links {
    font-size: 0.95rem;
//    color: #007395;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    border: 0;    
}

.help-center-button-links {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 8%;
    color: #007395;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

.tds-nav__list {
    .tds-nav__link {
        .tds-nav__item-text {
            font-size: 0.7rem;
            letter-spacing: .085rem;
            font-weight: 600;
        }
    }
    .tds-nav__link:hover{
        letter-spacing: 0.085rem;
    }
}

.product {
    font-size: 0.8125rem !important;
}