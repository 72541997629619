.report-options-main-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 0.3rem;
    width: 72%;

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media (max-width: 800px) {
        width: 100%;
        margin-right: 0.5rem;
    }

    .report-options-inner-div-title-act-identification {
        margin-bottom: 2rem;
    }

    .report-selection-div {
        padding-top: 3%;
        border-style: solid hidden hidden hidden;
        border-width: 1px;
        border-color: #909294;

        .national-programs-report-selection-form-elements-div {
            width: 100%;
            padding-top: 2%;
            padding-bottom: 1.5rem;

            .national-programs-report-selection-div {
                width: 50%;

                @media (max-width: 800px) {
                    width: 100%;
                }
            }
        }
    }

    .date-selection-criteria-outer-div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 3%;
        border-style: solid hidden hidden hidden;
        border-width: 1px;
        border-color: #909294;

        .policy-date-selection-criteria-inner-div {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .policy-date-range-fieldset {
                width: 50%;

                @media (max-width: 1050px) {
                    width: 100%;
                }

                .policy-date-range-div {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    .policy-date-range-input {
                        height: auto;
                        width: 8rem;
                        padding-bottom: 0.5625rem;
                        padding-left: 0.75rem;
                        padding-right: 2.4375rem;
                    }

                    .policy-date-range-to-div {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        margin-bottom: 0.3rem;
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        .date-selection-criteria-inner-div {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;

            @media (max-width: 850px) {
                flex-direction: column;
                width: 100%;
            }

            @media (max-width: 600px) {
                flex-direction: column;
                width: 100%;
            }

            .accident-date-range-fieldset {
                width: 50%;

                @media (max-width: 600px) {
                    flex-direction: column;
                    width: 100%;
                }

                @media (max-width: 575px) {
                    width: 100%;
                }

                .accident-date-range-fieldset-title {
                    @media (max-width: 600px) {
                        display: flex;
                        justify-content: center;
                    }
                }

                .accident-date-range-div {
                    display: flex;
                    flex-direction: row;
                    width: 100%;

                    @media (max-width: 575px) {
                        flex-direction: column;
                    }

                    .accident-date-range-inner-div {
                        @media (max-width: 575px) {
                            margin: 0 auto;
                            display: flex;
                            flex-direction: row;
                            justify-content: stretch;
                        }
                    }

                    .accident-date-range-to-div {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        margin-bottom: 0.3rem;
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;

                        @media (max-width: 575px) {
                            margin: 0 auto;
                        }
                    }
                }
            }

            .history-date-fieldset {

                .history-date-fieldset-title {
                    @media (max-width: 600px) {
                        display: flex;
                        justify-content: center;
                    }
                }

                .history-date-div {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;

                    @media (max-width: 850px) {
                        justify-content: flex-start;
                    }

                    @media (max-width: 600px) {
                        justify-content: center;
                    }

                    .history-date-input {
                        height: auto;
                        width: 11.5rem;
                        padding-bottom: 0.5625rem;
                        padding-left: 0.75rem;
                        padding-right: 2.4375rem;
                    }
                }
            }

            .detail-changes-history-date-fieldset {
                width: 30%;

                @media (max-width: 600px) {
                    width: 100%;
                }

                .detail-changes-fieldset-title {
                    @media (max-width: 600px) {
                        display: flex;
                        justify-content: center;
                    }
                }

                .detail-changes-history-date-div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    @media (max-width: 600px) {
                        flex-direction: column;
                        margin: 0 auto;
                        width: 30%;
                    }

                    .detail-changes-history-date-inner-div {
                        width: 49%;
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: center;

                        @media (max-width: 600px) {
                            display: flex;
                            flex-direction: column;
                        }

                        .detail-changes-date-range-input {
                            height: auto;
                            width: 7rem;
                            padding-bottom: 0.5625rem;
                            padding-left: 0.75rem;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }

    .optional-report-criteria-div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 3%;
        border-style: solid hidden hidden hidden;
        border-width: 1px;
        border-color: #909294;

        .plus-circle-div {
            display: flex;
            flex-direction: row;
        }

        .plus-circle-enabled {
            color: #007395;
            cursor: hand;
            margin-right: 0.5rem;
        }

        .plus-circle-disabled {
            color: #757575;
            pointer-events: none;
            margin-right: 0.5rem;
        }

        .p-font-disabled {
            color: #757575;
            pointer-events: none;
        }
        .css-1fpsjto:hover{
            /* -webkit-transform: scale(1.02); */
            -moz-transform: scale(1) !important;
            -ms-transform: scale(1) !important;
            /* transform: scale(1.02); */
            /* color: var(--chakra-colors-teal-600); */
        }
        .filters-main-div {

            .new-filters-component-form-elements-div {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

                @media (max-width: 800px) {
                    width: 100%;
                }

                .minus-circle-div {
                    width: 3.5%;

                    @media (max-width: 800px) {
                        width: 3.5%;
                    }
                }

                .new-filters-component-form-elements-inner-div {
                    margin-left: 0;

                    @media (max-width: 800px) {
                        margin-left: 3.5%;
                    }
                }
            }

            .filters-form-elements-div {
                width: 96.5%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-left: 3.5%;

                @media (max-width: 800px) {
                    flex-direction: column;
                    align-items: space-around;
                    width: 93%;
                    margin-left: 7%;
                }

                .add-filters-field {
                    width: 25%;

                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }

                .add-filters-operator {
                    width: 25%;

                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }

                .add-filters-value {
                    width: 35%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    @media (max-width: 800px) {
                        width: 100%;
                    }

                    .add-filters-value-input {
                        @media (max-width: 800px) {
                            width: 100%;
                        }

                        .add-filters-value-input-div {
                            height: auto;
                            width: 100%;
                            display: flex;
                            flex-wrap: nowrap;
                            justify-content: flex-end;

                            .add-filters-value-input-field {
                                width: 80%;

                                @media (max-width: 800px) {
                                    width: 90%;
                                }
                            }

                            .search-box-override-btn-report-options {
                                width: 20%;

                                @media (max-width: 800px) {
                                    width: 10%;
                                }
                            }
                        }
                    }

                    .add-filters-value-search-icon {
                        margin-left: auto;
                    }
                }

                .filters-reset-btn-div {
                    margin-top: 1.9rem;

                    @media (max-width: 800px) {
                        margin-top: 1rem;
                        margin-bottom: 1.5rem;
                    }

                    .report-options-reset-btn {
                        height: 2rem;

                        @media (max-width: 800px) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .claim-activity-filtering-main-div {

            .claim-activity-filtering-form-elements-div {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;

                @media (max-width: 800px) {
                    flex-direction: column;
                    width: 100%;
                }

                .dollar-type-field {
                    width: 23%;

                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }

                .claim-activity-operator {
                    width: 23%;

                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }

                .claim-activity-dollar-amt {
                    width: 23%;

                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }

                .claim-activity-ending-dollar-amt {
                    width: 23%;

                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }
            }
        }

        .large-loss-filter-main-div {

            .large-loss-filter-form-elements-div {
                width: 85%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-left: 3.5%;

                @media (max-width: 975px) {
                    width: 96.5%;
                }

                @media (max-width: 550px) {
                    flex-direction: column;
                    width: 100%;
                }

                .large-loss-total-div {
                    width: 45%;

                    @media (max-width: 550px) {
                        flex-direction: column;
                        width: 96.5%;
                    }
                }

                .large-loss-greater-than-div {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    width: 45%;

                    @media (max-width: 550px) {
                        flex-direction: column;
                        width: 96.5%;
                    }
                }
            }
        }

        .ranking-main-div {
            padding-bottom: 1.5rem;

            @media (max-width: 800px) {
                padding-bottom: 0;
            }

            .ranking-form-elements-div {
                width: 96.5%;
                display: flex;
                flex-direction: column;
                margin-left: 3.5%;

                @media (max-width: 800px) {
                    width: 100%;
                }

                .ranking-div {
                    width: 25%;

                    @media (max-width: 800px) {
                        width: 96.5%;
                        padding-bottom: 1.5rem;
                    }
                }
            }
        }

        .group-by-main-div {

            .new-grouping-component-form-elements-div {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

                @media (max-width: 800px) {
                    width: 100%;
                }

                .minus-circle-div {
                    width: 3.5%;

                    @media (max-width: 800px) {
                        width: 3.5%;
                    }
                }

                .new-grouping-component-form-elements-inner-div {
                    margin-left: 0;

                    @media (max-width: 800px) {
                        margin-left: 3.5%;
                    }
                }
            }

            .group-by-form-elements-div {
                width: 96.5%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-left: 3.5%;

                @media (max-width: 800px) {
                    flex-direction: column;
                    align-items: space-around;
                    width: 93%;
                    margin-left: 7%;
                }

                .group-by-field {
                    width: 25%;

                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }

                .group-by-label {
                    width: 25%;

                    @media (max-width: 800px) {
                        width: 100%;
                    }

                    .group-by-label-input {
                        width: 100%;

                        @media (max-width: 800px) {
                            width: 100%;
                        }
                    }
                }

                .add-grouping-checkbox-div {
                    width: 35%;
                    margin-top: auto;
                    margin-bottom: 1rem;

                    .add-grouping-checkbox-fieldset {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        margin: 0 auto;

                        @media (max-width: 1088px) {
                            flex-direction: column;
                            width: 100%;
                        }

                        .add-grouping-check-subtotal {
                            margin-left: 1rem;

                            @media (max-width: 1088px) {
                                margin-left: 0;
                            }
                        }

                        div {
                            @media (max-width: 1088px) {
                                margin-left: 0;
                            }
                        }
                    }

                    @media (max-width: 800px) {
                        width: 100%;
                    }
                }

                .group-by-reset-btn-div {
                    margin-top: 1.9rem;

                    @media (max-width: 800px) {
                        margin-top: 0.5rem;
                        margin-bottom: 1.5rem;
                    }

                    .report-options-reset-btn {
                        height: 2rem;

                        @media (max-width: 800px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .report-submission-div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 3%;
        border-style: solid hidden hidden hidden;
        border-width: 1px;
        border-color: #909294;

        .report-heading-div {
            width: 40%;

            @media (max-width: 800px) {
                width: 100%;
            }
        }

        .report-name-div {
            width: 40%;

            @media (max-width: 800px) {
                width: 100%;
            }
        }
    }

    .report-options-submit-button-div {
        width: 55%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1%;

        @media (max-width: 975px) {
            width: 100%;
            justify-content: space-around;
        }

        .report-submission-btn {
            min-width: 5rem;
            width: 30%;
            padding: 0.2rem;
            font-size: 1rem;

            @media (max-width: 975px) {
                width: 20%;
                padding: 0.1rem;

            }
        }
    }

    .saved-parameters-form-elements-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding-right: 0.1rem;

        @media (max-width: 1200px) {
            width: 100%;
        }

        @media (max-width: 800px) {
            flex-direction: column;
        }

        .saved-parameters-account-name-field {
            width: 32%;

            @media (max-width: 800px) {
                width: 100%;
            }
        }

        .saved-parameters-report-type-field {
            width: 32%;

            @media (max-width: 800px) {
                width: 100%;
            }
        }

        .saved-parameters-report-name-field {
            width: 32%;

            @media (max-width: 800px) {
                width: 100%;
            }
        }
    }

    .saved-parameters-search-results-div {
        outline: 0;
        box-shadow: none;

        :focus {
            outline: 0;
            box-shadow: none;
        }

        .homeform-act-search-btn {
            margin-left: 10px;
            margin-top: 12px;
        }

    }


    .saved-parameters-search-btn-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        outline: 0;
        box-shadow: none;

        :focus {
            outline: 0;
            box-shadow: none;
        }

        @media (max-width: 800px) {
            width: 100%;
            justify-content: center;
        }


        .saved-parameters-search-btn {
            min-width: 5rem;
            width: 15%;
            padding: 0.2rem;
            font-size: 1rem;
            outline: 0;
            box-shadow: none;

            :focus {
                outline: 0;
                box-shadow: none;
            }

            @media (max-width: 800px) {
                width: 100%;
                padding: 0.1rem;
            }
        }
    }

    .saved-parameter-search-results-main-div {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        border-width: 1px;
        border-style: solid hidden solid hidden;
        border-color: #dadbdb;
        margin-top: 2rem;
        margin-bottom: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .saved-parameter-search-results-delete-div {
            width: 75%;
            margin: 2rem auto;
        }

        .saved-parameter-search-results-header-div {
            padding-bottom: 0.5rem;
        }

        .saved-parameter-search-results-btn-div {
            width: 60%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 1.5rem;
            padding-bottom: 1.5rem;

            @media (max-width: 950px) {
                width: 70%;
            }

            @media (max-width: 850px) {
                width: 80%;
            }

            @media (max-width: 700px) {
                width: 90%;
            }

            @media (max-width: 500px) {
                width: 100%;
            }
        }

        .saved-parameter-search-results-rename-div {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 1rem;
            width: 60%;

            @media (max-width: 950px) {
                width: 70%;
            }

            @media (max-width: 850px) {
                width: 80%;
            }

            @media (max-width: 700px) {
                width: 90%;
            }

            @media (max-width: 500px) {
                width: 100%;
            }
        }
    }

    .report-filtering-results-btn-div {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;

        .report-filtering-results-btn {
            height: 2rem;
            width: 10rem;
        }
    }
}

.chakra-input {
    &.tds-field-invalid {
      border: 1px solid #874600;
    }
  }