.sidenavigation-main-div {
  display: flex;
  flex-wrap: nowrap;
  width: 28%;
  height: 100%;
  padding-left: 0.1rem;
  position: relative;
  left: -10px;

  .current {
    > a {
      &:before {
        content: "";
        right: 0;
        left: auto;
        border-right: 3px solid #e01719;
        border-left: 0;
      }

      &:after {
          content: "";
          font-weight: 600;
      }
    }
  }

  .active_link {
    &:first-child::before {
      border-left: 0 !important;
    }
  }

  .left-nav {
    &.hydrated {
      font-size: 17px;
      
    }
    a {
      font-size: 16px;
      letter-spacing: 0.014375rem;
      line-height: 15px;
      &:hover {
        border-right: #e01719;
      }
      &:first-child:not(.does-not-exist) {
        background-color: #ffffff !important;
        &:before {
          border-left: 0 !important;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    width: 20%;
  }

  @media (max-width: 800px) {
    width: 20%;
  }
  .active_link {
    background-color:  #ffff !important;
    font-size: 13px;
    letter-spacing: 0.085rem;
  }
}

::slotted(a:hover) {
  background-color: #ffffff !important;
}

::slotted(a), ::slotted(button) {
  padding: 0.275rem 0.75rem !important;
}
