.tds-alert-error{
    color: red;
}

.homeform-inner-div-policy-search {
    display: flex;
    flex-direction: row;        
    justify-content: space-between;    
    padding-bottom: 3%;
    width: 100%;        
    .tds-field__message {
        &:before {
          background-color: #fef8f2;
        }
    }

    @media (max-width: 800px) {
        width: 100%;       
        display: flex;
        flex-direction: column;  
        align-content: space-between;        
    }

    @media (max-width: 500px) {
        width: 100%;   
        display: flex;
        flex-direction: column;       
    }   
    
    .policy-selection-div-outer {  
        display: flex;
        flex-direction: column;
        width: 55%;
                
        @media (max-width: 800px) {
            width: 100%;          
        }
    
        @media (max-width: 500px) {
            width: 100%;                   
        }

        .policy-selection-div-inner{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;                    
            width: 100%;  
            align-items: flex-start;            
                         
            @media (max-width: 1149px) {
                width: 100%;   
                flex-direction: column;  
                justify-content: space-around;
            }
        
            @media (max-width: 800px) {                
                width: 100%;      
                flex-direction: column;                
            }

            .home-form-policy-input {  
                width: 40%;                                                     
                
                @media (max-width: 1149px) {
                    width: 100%;   
                }
            
                @media (max-width: 800px) {
                    width: 100%;                    
                }
            }

            .home-form-policy-input-dropdown {                
                              
                @media (max-width: 1149px) {
                    width: 100%;               
                }                       
            }         
            
            .program-codes-input-label {
                margin-top: 1.5%;

                @media (max-width: 1149px) {
                    margin-top: 0;                                       
                }
            }

            .program-codes-input {
                width: 40%;                

                @media (max-width: 1149px) {
                    width: 100%;                    
                }
            
                @media (max-width: 800px) {
                    width: 100%;                    
                }
            }
            
            .policy-number-add-button-container {   
                margin-top: 2px;           
                margin-bottom: 1.5rem;
                width: 15%;                              
                
                @media (max-width: 1149px) {
                    margin-top: 0;
                    width: 100%;                   
                }
            
                @media (max-width: 800px) {
                    margin-top: 0;
                    width: 100%;                       
                }
            }

            .program-codes-add-btn-div {        
                margin-top: 1.5%;

                @media (max-width: 1149px) {
                    margin-top: 0;
                    margin-bottom: 3%;   
                    width: 100%;                 
                }
            }
        }
       
    }

    .policy-number-display-div{
        display: flex;
        flex-direction: column;
        width: 40%;    
        align-content: flex-start;        
                
        @media (max-width: 800px) {
            width: 100%;        
        }
    
        @media (max-width: 500px) {
            width: 100%;             
        }  
        
        .policy-number-textarea-div{
            width: 100%;

            @media (max-width: 800px) {
                width: 100%;        
            }
        
            @media (max-width: 500px) {
                width: 100%;             
            }               
          
            .policy-number-display-inner-div {
                width: 100%;                                              
            }
            
        }

        .policy-number-button-container{           
            width: 100%;
            display: flex;           
            flex-direction: row;
            justify-content: space-between;            
           
            @media (max-width: 1149px) {
                width: 100%;        
                flex-direction: column;    
                margin-bottom: 1rem;                     
            }
        
            @media (max-width: 800px) {
                width: 100%;     
                flex-direction: column;    
                margin-bottom: 1rem;                
            }   
            
            .policy-nbr-remove-sel-btn {                
                @media (max-width: 1149px) {
                    margin-bottom: 3%;
                    width: 100%;                                 
                }
            
                @media (max-width: 800px) {
                    width: 100%;                                     
                }   
            }

            .policy-nbr-clr-all-btn {              
                @media (max-width: 1149px) {      
                    margin-bottom: 3%;             
                    width: 100%;                  
                }
            
                @media (max-width: 800px) {                    
                    width: 100%;                       
                }   
            }
        }
    }
}