.dashboard-main-div {    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;        
    width: 100%;     

    // @media (max-width: 1200px) {
    //     width: 80%;        
    // }

    // @media (max-width: 800px) {
    //     width: 80%;        
    // }     
}