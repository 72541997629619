.claim-activity-table-main-div {
    width: 100%;

    .claim-activity-table-activities-div {        
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-left: 0.5625rem;

        .claim-activity-table-activities-p {            
            padding: 0.5625rem 1.125rem 0.5625rem 0;
            margin-bottom: 0;
            border-color: transparent;
        }

        .claim-activity-table-fieldset {            
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: 0;

            .claim-activity-table-activities-span {                
                color: #46494d;
                font-size: 1rem;
            }
        }
    }

    .claim-activity-table-account-name-div {       
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        border-style: solid hidden solid hidden;
        border-color: #909294;
        border-width: 1px;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .claim-activity-daily-alerts-subscription-link {            
            margin: 0;
            padding-right: 0.5625rem;
            font-size: 0.95rem;
            text-decoration: none;
            color: #007395;
        }
    }

    .claim-activity-accounts-modal-btn-div {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;                      

        .claim-activity-accounts-modal-close-btn {
            height: 2rem;
            width: 10rem;
        }
    }    
}