.input-remove-arrows {
    &.chakra-input {
        padding-left: 20px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.lbl_gtet {
    text-transform: uppercase;
    font-size: 12px;
}

.tds-chkra-dropdown {
    select {
        padding: 0 25px 0 15px;
        font-size: 1.2rem;
    }
    .chakra-select__icon-wrapper {
        display: none;
    }
}

.chakra-table {
    .tbl-header-seprator {
        border-bottom: 2px solid;
        position: absolute;
        width: 100%;
    }
    
    .tbl-list-header {
        background: #fff;
        border: 0;
    }
    // Causes small lists to look off
    // .tbl-list-scroll {
    //     height: 240px;
    // }
}
.chakra-modal__body {
    .list-page {
        position: relative;
        margin: 10% auto;
        .action-box {
            margin: 0 auto;
        }
        svg {
            width: 50%;
        }
    }
}

/* TDS V8 CSS */
input:focus, select:focus, textarea:focus {
    outline: 0 !important;
    box-shadow: rgb(0, 145, 235) 0px 0px 0px 0.0625rem inset;
}

.hydrated {
    ::slotted(select) {
        font-size: 1.125rem !important;
    }
    select {
        font-size-adjust: 0.45;
    }
    option {
        font-size: 1rem !important;
    }
}

.doNotShow {
    color: #46494d;
}

[class*=tds-button--]:hover {
    box-shadow: none !important;
    transform: scale(1.02);
}

.tds-button--small {
    padding: .3125rem .875rem;
    font-size: .75rem;
    letter-spacing: .1rem;
}

.tds-modal__dialog--large {
    .tds-modal__dialog-container {
        width: 50rem;
    }
}

.one-click-reports-table {
    [class*=tds-button--]:hover {
        box-shadow: none !important;
        transform: none;
    }
}

.tds-tooltip {
    width: auto;
    min-width: 390px !important;
    z-index: 1002 !important;
    &.tds-tooltip-home {
        height: 60px;
    }
}

.chakra-table {
    [type=checkbox], 
    [type=radio] {
        height: auto;
    }
}